import { useMemo } from 'react'
import { useParams } from '@reach/router'
import useRolePermission from '_/hooks/use-role-permission'
import { userSelector } from '_/modules/authentication/selectors'
import { getStepStatusLogSelector } from '_/modules/budget/selectors'
import {
  currentServiceOrderSelector,
  getButtonsActionByNameSelector,
} from '_/modules/service-orders/selectors'
import { useSelector } from 'react-redux'

export function CanDoButtonAction({ nameButton }) {
  const { budgetId } = useParams()
  const serviceOrder = useSelector(currentServiceOrderSelector)

  const user = useSelector(userSelector)?.toJS()
  const buttonAction = useSelector(getButtonsActionByNameSelector(nameButton))
  const stepStatusLog = useSelector(getStepStatusLogSelector)
  const { isTradesman, isSAAS } = useRolePermission()

  const lastStepStatusLogWithBudget = useMemo(
    () => stepStatusLog.filter(log => budgetId === log.budgetId?.toString())[0],
    [serviceOrder?.activeBudget, stepStatusLog, budgetId]
  )

  const allowedStepStatus = useMemo(
    () => buttonAction?.optionsStepServiceOrder,
    [buttonAction?.optionsStepServiceOrder]
  )

  const canDoButtonActionNew = useMemo(() => {
    if (isTradesman || isSAAS) {
      if (
        stepStatusLog[0]?.tradesmanId === user.getServiceProviderId ||
        serviceOrder?.tradesmanInPoolCandidates ||
        isSAAS
      ) {
        if (budgetId) {
          const isRouteBudgetEqualToSteplogBudget =
            stepStatusLog[0]?.budgetId?.toString() === budgetId

          if (isRouteBudgetEqualToSteplogBudget || !stepStatusLog[0]?.budgetId) {
            return allowedStepStatus?.some(
              stepStatus => stepStatus.name === stepStatusLog[0]?.stepStatus
            )
          }
        }

        return allowedStepStatus?.some(
          stepStatus => stepStatus.name === stepStatusLog[0]?.stepStatus
        )
      }

      return false
    }

    if (budgetId) {
      return (
        allowedStepStatus?.some(
          stepStatus => stepStatus.name === lastStepStatusLogWithBudget?.stepStatus
        ) || false
      )
    }

    return (
      allowedStepStatus?.some(stepStatus => stepStatus.name === stepStatusLog[0]?.stepStatus) ||
      false
    )
  }, [stepStatusLog, buttonAction, lastStepStatusLogWithBudget, budgetId, user, serviceOrder])

  return canDoButtonActionNew
}

export const PERMISSIONS = {
  ACCEPT_REFUSE_SERVICE_ORDER: 'acceptRefuseServiceOrder', // Aceitar ou recusar chamado
  ACCEPT_REFUSE_INTERNAL_BUDGET: 'acceptRefuseInternalBudget', // Aceitar ou recusar orçamento interno
  ADJUST_FLOW: 'adjustFlow', // Ajustar fluxo
  REFERA_PROFILE_ACCESS: 'referaProfileAccess', // Acesso dos perfis (middleware)
  REPORT_ACCESS: 'reportAccess', // Acessar Relatórios
  SCHEDULE_SERVICE_EXECUTION: 'scheduleServiceExecution', // Agendar execução do serviço
  SCHEDULE_BUDGET_VISIT: 'scheduleBudgetVisit', // Agendar visita para orçamento
  RECEIVABLE_ANTICIPATIONS: 'receivableAnticipations', // Antecipações de recebíveis
  APPROVE_ANTICIPATION: 'approveAntecipation', // Aprovar antecipações
  APPROVE_REJECT_BUDGET_CANCEL_ACTION: 'approveRejectBudgetCancelAction', // Aprovar/recusar orçamento e cancelar ação
  APPROVE_REPROVE_SERVICE_ORDER_FINALIZATION: 'approveReproveServiceOrderFinalization', // Aprovar/reprovar finalização do chamado
  JUSTIFIED_DELAY: 'justifiedDelay', // Atrasos justificados
  RATE_SERVICE: 'rateService', // Avaliar serviço
  BLOCK_FINANCIAL_TRANSFERS: 'blockFinancialTransfers', // Bloquear repasses financeiros
  INTERMEDIARY_REGISTRY: 'intermediaryRegistry', // Cadastro da intermediária
  TRADESMAN_REGISTRY: 'tradesmanRegistry', // Cadastro do prestador
  CANCEL_REACTIVATE_SERVICE_ORDER: 'cancelReactivateServiceOrder', // Cancelar e reativar chamado
  CATEGORY: 'category', // Categoria
  CATEGORIES: 'categories', // Categorias de manutenção
  SERVICE_ORDER: 'serviceOrder', // Chamados
  SERVICE_ORDER_CLASSIFICATIONS: 'serviceOrderClassifications', // Classificações do chamado
  SPECIFIC_CLASSIFICATIONS: 'specificClassifications', // Classificações específicas
  GENERAL_CLASSIFICATIONS: 'generalClassifications', // Classificações gerais
  APPROVAL_CERTIFICATE: 'approvalCertificate', // Comprovante de aprovação
  INTERMEDIARY_CONFIGS: 'intermediaryConfigs', // Configurações da intermediária / Gestão de intermediárias
  TRADESMAN_CONFIGS: 'tradesmanConfigs', // Configurações do prestador / Gestão de prestadores
  REALSTATE_DATA: 'realstateData', // Dados do imóvel
  RESPONSIBLE_DATA: 'responsibleData', // Dados dos envolvidos
  DIRECT_CALL_TO_REFERA: 'directCallToRefera', // Direcionar atendimento à Refera
  DUPLICATE_SERVICE_ORDER: 'duplicateServiceOrder', // Duplicar chamado
  EDIT_TAX_RATE: 'editTaxRate', // Editar alíquota
  EDIT_PAYER_DATA: 'editPayerData', // Editar dados do pagador
  SEND_BUDGET_TO_PAYER: 'sendBudgetToPayer', // Enviar orçamento ao pagador
  FINISH_SERVICE_ORDER_CANCEL_ACTION: 'finishServiceOrderCancelAction', // Finalizar o chamado e cancelar ação
  END_SERVICE_CANCEL_ACTION: 'endServiceCancelAction', // Finalizar serviço e cancelar ação
  FLOW: 'flow', // Fluxo
  PROCESS_FLOWS: 'processFlows', // Fluxos de processos
  PERMISSION_GROUPS: 'permissionGroups', // Grupos de permissão
  INTERMEDIARY_DOCUMENTS: 'intermediaryDocuments', // ID da Intermediária (CPF/CNPJ)
  TRADESMAN_DOCUMENTS: 'tradesmanDocuments', // ID do Prestador (CPF/CNPJ)
  IMPORT_TRADESMAN: 'importTradesman', // Importar prestadores
  INSERT_SERVICE_IMAGES: 'insertServiceImages', // Inserir imagens do serviço
  INSERT_INVOICE: 'insertInvoice', // Inserir Nota Fiscal
  INTERMEDIARY: 'intermediary', // Intermediárias
  MESSENGER: 'messenger', // Mensageria
  MESSENGER_ADVANCE_NOTICE: 'messengerAdvanceNotice', // Mensageria - Aviso prévio
  MESSENGER_SELF_CHECKOUT: 'messengerSelfCheckout', // Mensageria - Self checkout
  BUDGETS: 'budgets', // Orçamentos
  INTERNAL_BUDGETS: 'internalBudgets', // Orçamentos internos
  ORIGINAL_BUDGETS: 'originalBudgets', // Orçamentos originais
  REFERA_PAYMENTS: 'referaPayments', // Pagamentos da Refera
  REFERA_PAYMENT_INSTALLMENTS: 'referaPaymentInstallments', // Parcelas de Pagamento da Refera
  REFERA_RECEIVABLE_INSTALLMENTS: 'referaReceivableInstallments', // Parcelas de Recebimento da Refera
  HELP_REQUEST: 'helpRequest', // Pedidos de ajuda
  HELP_REQUEST_INTERMEDIARY_TO_TRADESMAN: 'helpRequestIntermediaryToTradesman', // Pedidos de ajuda - Intermediária para Prestador
  HELP_REQUEST_INTERMEDIARY_TO_REFERA: 'helpRequestIntermediaryToRefera', // Pedidos de ajuda - Intermediária para Refera
  HELP_REQUEST_TRADESMAN_TO_INTERMEDIARY: 'helpRequestTradesmanToIntermediary', // Pedidos de ajuda - Prestador para Intermediária
  HELP_REQUEST_TRADESMAN_TO_REFERA: 'helpRequestTradesmanToRefera', // Pedidos de ajuda - Prestador para Refera
  HELP_REQUEST_REFERA_TO_INTERMEDIARY: 'helpRequestReferaToIntermediary', // Pedidos de ajuda - Refera para Intermediária
  HELP_REQUEST_REFERA_TO_TRADESMAN: 'helpRequestReferaToTradesman', // Pedidos de ajuda - Refera para Prestador
  REFERA_PROFILE: 'referaProfile', // Perfis da Refera
  PERMISSIONS: 'permissions', // Permissões
  PERMISSIONS_BY_GROUP: 'permissionsByGroup', // Permissões por grupo
  PROFILE_PERMISSIONS: 'profilePermissions', // Permissões por perfil
  TRADESMAN: 'tradesman', // Prestadores
  PRIORITY: 'priority', // Prioridade
  PROBLEMS: 'problems', // Problemas
  REFERA_RECEIVABLES: 'referaReceivables', // Recebimentos da Refera
  REPROVE_ANTICIPATION: 'reproveAntecipation', // Reprovar antecipações
  PAYMENT_RESPONSIBLE: 'paymentResponsible', // Responsabilidade pelo pagamento
  SERVICE_ORDER_RESPONSIBLE: 'serviceOrderResponsible', // Responsável pelo chamado
  REVIEW_RETURN_BUDGET_CANCEL_ACTION: 'reviewReturnBudgetCancelAction', // Revisar/devolver orçamento e cancelar ação
  SELECT_TRADESMAN_TO_PREAPPROVED_PROPOSAL: 'selectTradesmanToPreapprovedProposal', // Selecionar prestador para proposta pré-aprovada
  SELECT_TRADESMAN: 'selectTradesman', // Selecionar prestadores para chamado
  SERVICES: 'services', // Serviços
  BUDGET_SERVICES: 'budgetServices', // Serviços do orçamento
  REQUEST_WARRANTY: 'requestWarranty', // Solicitar garantia
  REQUESTER_DATE_SUGGESTION: 'requesterDateSuggestion', // Sugestão de dias e horário do solicitante
  EXTERNAL_TEMPLATES: 'externalTemplates', // Templates externos
  INTERNAL_TEMPLATES: 'internalTemplates', // Templates internos
  INTERMEDIARY_USERS: 'intermediaryUsers', // Usuários de intermediárias
  TRADESMAN_USERS: 'tradesmanUsers', // Usuários de prestadores
  REFERA_USERS: 'referaUsers', // Usuários Refera
  VALIDATE_INVALIDATE_BUDGET_CANCEL_ACTION: 'validateInvalidateBudgetCancelAction', // Validar/invalidar orçamento e cancelar ação
  ACCEPT_SERVICEORDER_FOR_PROVIDER: 'acceptServiceorderForProvider', // Aceitar chamado para prestador
  ACCEPT_INNER_BUDGET_FOR_PROVIDER: 'acceptInnerBudgetForProvider', // Aceitar O.I. pelo prestador
}

export const PERMISSIONS_ACTIONS = {
  ADD: 'create', // add
  READ: 'read', // ver
  EDIT: 'update', // editar
  DELETE: 'delete',
}
